import React, { useState, useEffect } from 'react';
import { AreaChart, Area, XAxis, YAxis, CartesianGrid,Label, Tooltip, ResponsiveContainer,ReferenceLine } from 'recharts';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';


//export default class Impfstatus extends PureComponent {

//  render() {
  const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
    },
    paper: {
      padding: '10px',
      textAlign: 'center',
      color: theme.palette.text.secondary,
    },
  }));

export default function Impfstatus(params) {
    const [data, setData] = useState({ data_chart: [], data_sum:{a:0,b:0}});
    useEffect(() => {
        async function loadData() {
            const link = 'https://coronainfo.landkreis-cham.de/manage/api/index.php?mode=impfstatus';
            //console.log(link);
            const response = await fetch(link);
            const json = await response.json();

            setData(json);
        }
        loadData();
    },[]);

    const classes = useStyles();
    return (
      <div className={classes.root}>
      <Grid container spacing={3}>
          <Grid item xs={12}>
              <Paper elevation={2}>
                <Box p={2}>
                  <h3>IMPFSTATUS IM LANDKREIS CHAM</h3>
                  <p>Mittlerweile wurden im Landkreis Cham {data.data_sum.a} ({((data.data_sum.a/127962)*100).toFixed(1)}%) Personen mit der Erstimpfung versorgt.
      Bereits {data.data_sum.b} ({((data.data_sum.b/127962)*100).toFixed(1)}%) Personen haben ihre Zweitimpfung erhalten, dabei sind auch die Impfungen von Genesenen inbegriffen, und {data.data_sum.d} ({((data.data_sum.d/127962)*100).toFixed(1)}%) Personen wurden mit Auffrischungsimpfungen versorgt. </p>
      <p>Davon wurden {data.data_sum.c} Impfdosen in den Hausarztpraxen verabreicht.</p>
            <ResponsiveContainer width="100%" height={400}>
              <AreaChart
                width={750}
                height={400}
                data={data.data_chart}
                margin={{
                  top: 10,
                  right: 30,
                  left: 0,
                  bottom: 0,
                }}
              >
                  <defs>
                      <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                      <stop offset="5%" stopColor="#8884d8" stopOpacity={0.8}/>
                      <stop offset="95%" stopColor="#8884d8" stopOpacity={0}/>
                      </linearGradient>
                      <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
                      <stop offset="5%" stopColor="#82ca9d" stopOpacity={0.8}/>
                      <stop offset="95%" stopColor="#82ca9d" stopOpacity={0}/>
                      </linearGradient>
                      <linearGradient id="colorHv" x1="0" y1="0" x2="0" y2="1">
                      <stop offset="5%" stopColor="#ffbf00" stopOpacity={0.8}/>
                      <stop offset="95%" stopColor="#ffbf00" stopOpacity={0}/>
                      </linearGradient>

                  </defs>

                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="date" />
                <YAxis domain={[0, 127962]} ticks={[0,31990,63981,95971,127962]} tickFormatter={tick => `${Math.round((tick/127962)*100)} %` } />
                <Tooltip  />
                <ReferenceLine y={127962} stroke="green"  alwaysShow={true} >
                  <Label value="Landkreisbürger (127.962 Stand 30.06.2020)"  position="bottom" />
                </ReferenceLine>
                <Area type="monotone" dataKey="3" name="Auffrischungsimpfung" stackId="3" stroke="#ffbf00" fillOpacity={1} fill="url(#colorHv)" />
                <Area type="monotone" dataKey="2" name="Zweitimpfung" stackId="2" stroke="#82ca9d" fillOpacity={1} fill="url(#colorPv)" />
                <Area type="monotone" dataKey="1" name="Erstimpfung"  stackId="1" stroke="#8884d8" fillOpacity={1} fill="url(#colorUv)" />
                </AreaChart>
            </ResponsiveContainer>
            </Box>
            </Paper>
                </Grid>
            </Grid>
        </div>
    );
  }
