import React, { useState, useEffect } from 'react';
import {AreaChart, Area,  Legend,  XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Dvi from './Dvi';

const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
    },
    paper: {
      padding: '10px',
      textAlign: 'center',
      color: theme.palette.text.secondary,
    },
  }));

//  render() {
export default function Behandlungen(params) {
    const [data, setData] = useState({ data_chart: [], data_sum:{a:0,b:0}});
    useEffect(() => {
        async function loadData() {
            const link = 'https://coronainfo.landkreis-cham.de/manage/api/index.php?mode=faelle';
            //console.log(link);
            const response = await fetch(link);
            const json = await response.json();

            setData(json);
        }
        loadData();
    },[]);

    const classes = useStyles();
    return (
        <div className={classes.root}>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Paper elevation={2}>
                        <Box p={2}>
            <h3>BEHANDLUNGSBEDÜRFTIGE COVID-19-PATIENTEN IN DEN SANA KLINIKEN DES LANDKREISES CHAM</h3>
            <p>Bisheriger Höchststand am {data.data_sum.date}: {data.data_sum.stationaer} Patienten ({data.data_sum.intensiv} Intensivstation, {data.data_sum.beatmet} beatmete Fälle) </p>
            <Dvi />
      <ResponsiveContainer width="100%" height={400}>
            <AreaChart width={500} height={400} data={data.data_chart}
            margin={{ top: 10, right: 30, left: 0, bottom: 0 }}>

<defs>
                      <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                      <stop offset="5%" stopColor="#8884d8" stopOpacity={0.8}/>
                      <stop offset="95%" stopColor="#8884d8" stopOpacity={0}/>
                      </linearGradient>
                      <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
                      <stop offset="5%" stopColor="#82ca9d" stopOpacity={0.8}/>
                      <stop offset="95%" stopColor="#82ca9d" stopOpacity={0}/>
                      </linearGradient>
                      <linearGradient id="colorDv" x1="0" y1="0" x2="0" y2="1">
                      <stop offset="5%" stopColor="#e64040" stopOpacity={0.8}/>
                      <stop offset="95%" stopColor="#e64040" stopOpacity={0}/>
                      </linearGradient>
                  </defs>

            <XAxis dataKey="date" />
            <YAxis  domain={[0, 55]}/>
            <CartesianGrid strokeDasharray="3 3" />
            <Tooltip />
            <Legend verticalAlign="top" height={50}/>

                <Area type="monotone" dataKey="stationaer" name="bestätigte stationäre Covid-19 Fälle"  stroke="#8884d8" fillOpacity={1} fill="url(#colorUv)" />
                <Area type="monotone" dataKey="intensiv" name="Fälle auf Intensiv/IMC"  stroke="#82ca9d" fillOpacity={1} fill="url(#colorPv)" />
                <Area type="monotone" dataKey="beatmet" name="beatmete Fälle"  stroke="#e64040" fillOpacity={1} fill="url(#colorDv)" />
            </AreaChart>
      </ResponsiveContainer>
      </Box>
      </Paper>
                </Grid>
            </Grid>
        </div>
    );
  }
