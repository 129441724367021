import logo from './logo_lk.svg';
import './App.css';
import CssBaseline from '@material-ui/core/CssBaseline';
//import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Alert from '@material-ui/lab/Alert';
import { Offline, Online } from "react-detect-offline";

//import './components/Inzidenz'
//import Alterskohorten from './components/Alterskohorten';
import Inzidenz from './components/Inzidenz';
import Impfstatus from './components/Impfstatus';
import Behandlungen from './components/Behandlung';
import Uebersicht from './components/Uebersicht';
//import Map from "./components/Map";
import Footer from "./components/Footer";
import PWAPrompt from 'react-ios-pwa-prompt'


import InstallerPrompt from './components/InstallerPrompt';

function App() {
const polling = {enabled:true, url:'https://coronainfo.landkreis-cham.de/manage/api/index.php?mode=dvi2',intervall:5000,timeour:5000};
  return (


    <div className="App">
      <header className="App-header">
        <div className="topBar">
            <span className="App-title">Corona Lage</span>
            <img src={logo} className="App-logo" alt="logo" />
        </div>
      </header>
      <CssBaseline />
      <Container maxWidth="xl">
        
        <InstallerPrompt/>
        <Uebersicht/>
        <Inzidenz/>
        
        
        <Behandlungen/>
        <Impfstatus/>

        <Footer />
      </Container>
      <PWAPrompt 
        copyTitle="Zum Startbildschirm hinzufügen"
        copyBody="Diese Seite verfüg über App Funktionen. Fügen Sie sie dem Startbildschirm für Vollbild- und Offline Funktionen hinzu."
        copyShareButtonLabel="1) Drücken Sie den Teilen Button"
        copyAddHomeButtonLabel="2) Wählen Sie 'Zum Starbildschirm hinzufügen'"
        copyClosePrompt="Abbrechen"
      />      
    </div>
  );
}

export default App;
