import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';


const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
    },
    paper: {
      padding: '10px',
      textAlign: 'center',
      color: theme.palette.text.secondary,
    },
  }));

export default function Footer() {
    const classes = useStyles();
    //const preventDefault = (event) => event.preventDefault();
    return (
        <div className={classes.root}>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Paper elevation={2}>
                        <Box p={2}>
                            <Typography className={classes.root}>
                                <Link href="https://www.landkreis-cham.de/meta/impressum/" target="_blank" rel="noopener">
                                    Impressum
                                </Link>
                                <span> | </span>
                                <Link href="https://www.landkreis-cham.de/meta/datenschutz/" target="_blank" rel="noopener">
                                    Datenschutz
                                </Link>
                                <span> | </span>
                                <Link href="https://www.landkreis-cham.de" target="_blank" rel="noopener">
                                    Landkreis Cham
                                </Link>
                            </Typography>
                        </Box>
                    </Paper>
                </Grid>
            </Grid>
        </div>
  );
}