import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';


const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
    },
    paper: {
      padding: '10px',
      textAlign: 'center',
      color: theme.palette.text.secondary,
    },
  }));

export default function Uebersicht() {
    const [data, setData] = useState({ data_chart: [], data_sum:{a:0,b:0}});
    useEffect(() => {
        async function loadData() {
            const link = 'https://coronainfo.landkreis-cham.de/manage/api/index.php?mode=rki';
            //console.log(link);
            const response = await fetch(link);
            const json = await response.json();

            setData(json);
        }
        loadData();
    },[]);

    const [dataInzidenz, setDataInzidenz] = useState({ data_chart: [], data_sum:{a:0,b:0}});
    useEffect(() => {
        async function loadDataInzidenz() {
            const link = 'https://coronainfo.landkreis-cham.de/manage/api/index.php?mode=inzidenz';
            //console.log(link);
            const response = await fetch(link);
            const json = await response.json();

            setDataInzidenz(json);
        }
        loadDataInzidenz();
    },[]);

    const classes = useStyles();
    return (
        <div className={classes.root}>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Paper elevation={2}>
                        <Box p={2}>
                            <h3>ALLGEMEINE ÜBERSICHT</h3>
                            <p><strong>Die 7-Tage-Inzidenz liegt bei {Number(data.cases7_per_100k).toFixed(2)}</strong></p>
                            <p>Insgesamt liegen {data.cases} bestätigte Fälle vor, die seit Beginn der Pandemie positiv getestet wurden.</p>
                            <p>Als genesen gelten derzeit {dataInzidenz.data_sum.genesen} Personen.</p>
                            <p>Die Zahl der Todesfälle beläuft sich aktuell auf {data.deaths}.</p>
                            <p><small>Quelle: RKI Letzte aktualisierung: {data.last_update}</small></p>
                        </Box>
                    </Paper>
                </Grid>
            </Grid>
        </div>
  );
}