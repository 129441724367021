import React, { useState, useEffect } from 'react';
import { ComposedChart , Legend, Line,Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer,ReferenceLine } from 'recharts';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';

/*
    const useStyles = makeStyles((theme) => ({
        root: {
        display: 'flex',
        flexWrap: 'wrap',
        '& > *': {
            margin: theme.spacing(1),
            width: theme.spacing(16),
            height: theme.spacing(16),
        },
        },
    }));
    */

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: '10px',
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
}));

  export default function Inzidenz() {
    const [data, setData] = useState({ data_chart: [], data_sum:{a:0,b:0}});
    useEffect(() => {
        async function loadData() {
            const link = 'https://coronainfo.landkreis-cham.de/manage/api/index.php?mode=inzidenz';
            //console.log(link);
            const response = await fetch(link);
            const json = await response.json();

            setData(json);
        }
        loadData();
    },[]);


      const classes = useStyles();
    return (
        <div className={classes.root}>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Paper elevation={2}>
                        <Box p={2}>
                            <h3>7-TAGE-INZIDENZ PRO 100.000 EINWOHNER IM LANDKREIS CHAM</h3>
                            <ResponsiveContainer width="100%" height={400}>
                                <ComposedChart  width={500} height={400} data={data.data_chart}
                                margin={{ top: 10, right: 30, left: 0, bottom: 0 }}>
                                <defs>
                                    <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                                        <stop offset="5%" stopColor="#8884d8" stopOpacity={0.4}/>
                                        <stop offset="95%" stopColor="#8884d8" stopOpacity={0}/>
                                    </linearGradient>
                                </defs>
                                <XAxis dataKey="date" />
                                <YAxis domain={[0, data.data_sum.max]}/>
                                <CartesianGrid strokeDasharray="3 3" />
                                <Tooltip />
                                <Legend verticalAlign="top" height={36}/>
                                <Line name="7 Tage Inzidenz" type="monotone" dataKey="d7" stroke="#006EC7" strokeWidth={3} />

                                </ComposedChart >
                            </ResponsiveContainer>
                        </Box>
                    </Paper>
                </Grid>
            </Grid>
        </div>
  );
}