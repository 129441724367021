import React, { useState, useEffect } from 'react';
export default function Dvi() {
    const [data, setData] = useState({ data_chart: [], data_sum:{a:0,b:0}});
    useEffect(() => {
        async function loadData() {
            const link = 'https://coronainfo.landkreis-cham.de/manage/api/index.php?mode=dvi2';
            //console.log(link);
            const response = await fetch(link);
            const json = await response.json();

            setData(json);
        }
        loadData();
    },[]);

    return (
            <p>Von den zur Zeit {data.betten_gesamt} verfügbaren Intensivbetten der Sanaklinken im Landkreis Cham sind aktuell {data.betten_belegt} belegt.  <small>Stand der Meldung:  {data.daten_stand}</small></p>
  );
}