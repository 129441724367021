import React from 'react';
import PWAInstallerPrompt from 'react-pwa-installer-prompt';
import Button from '@material-ui/core/Button';

export default function InstallerPrompt() {
    return (
        <PWAInstallerPrompt 
            render={({ onClick }) => (
                <div className="messageBar">
                    <div className="messageBarInfo">
                        App für schnelleren Zugriff und offline Verfügbarkeit Installieren?
                    </div>
                    <div className="messageBarAction">
                        <Button variant="contained" color="primary" onClick={onClick}>
                            Installieren
                        </Button >
                    </div>
                </div>
            )}
        callback={(data) => console.log(data)} 
      />
  );
}